function ChordSheets() {
  return (
    <div>
      <h1>Common chords sheet:</h1>
      <div className="chord-sheet" style={{ backgroundColor: '#c7c3cd' }}>
        <img src="/images/chord-diagrams/A.png" alt="A" />
        <img src="/images/chord-diagrams/A7.png" alt="A7" />
        <img src="/images/chord-diagrams/Ab.png" alt="Ab" />
        <img src="/images/chord-diagrams/Am.png" alt="Am" />
        <img src="/images/chord-diagrams/B.png" alt="B" />
        <img src="/images/chord-diagrams/B5.png" alt="B5" />
        <img src="/images/chord-diagrams/B7.png" alt="B7" />
        <img src="/images/chord-diagrams/Bb.png" alt="Bb" />
        <img src="/images/chord-diagrams/Bb7.png" alt="Bb7" />
        <img src="/images/chord-diagrams/Bbm.png" alt="Bbm" />
        <img src="/images/chord-diagrams/Bm.png" alt="Bm" />
        <img src="/images/chord-diagrams/Csm.png" alt="C#m" />
        <img src="/images/chord-diagrams/C.png" alt="C" />
        <img src="/images/chord-diagrams/C7.png" alt="C7" />
        <img src="/images/chord-diagrams/C7-barre.png" alt="C7-barre" />
        <img src="/images/chord-diagrams/Cm.png" alt="Cm" />
        <img src="/images/chord-diagrams/D.png" alt="D" />
        <img src="/images/chord-diagrams/D7.png" alt="D7" />
        <img src="/images/chord-diagrams/Db.png" alt="Db" />
        <img src="/images/chord-diagrams/Dm.png" alt="Dm" />
        <img src="/images/chord-diagrams/Dsharpdim.png" alt="D#dim" />
        <img src="/images/chord-diagrams/E-flat.png" alt="Eb" />
        <img src="/images/chord-diagrams/E.png" alt="E" />
        <img src="/images/chord-diagrams/E7.png" alt="E7" />
        <img src="/images/chord-diagrams/Eb7.png" alt="Eb7" />
        <img src="/images/chord-diagrams/Em.png" alt="Em" />
        <img src="/images/chord-diagrams/E.png" alt="E" />
        <img src="/images/chord-diagrams/F.png" alt="F" />
        <img src="/images/chord-diagrams/F5.png" alt="F5" />
        <img src="/images/chord-diagrams/F7.png" alt="F7" />
        <img src="/images/chord-diagrams/F-barre.png" alt="F-barre" />
        <img src="/images/chord-diagrams/Fm.png" alt="Fm" />
        <img src="/images/chord-diagrams/Fm-barre.png" alt="Fm-barre" />
        <img src="/images/chord-diagrams/Fs.png" alt="F#" />
        <img src="/images/chord-diagrams/Fs7.png" alt="F#7" />
        <img src="/images/chord-diagrams/Fsm.png" alt="F#m" />
        <img src="/images/chord-diagrams/G.png" alt="G" />
        <img src="/images/chord-diagrams/G7.png" alt="G7" />
        <img src="/images/chord-diagrams/Gm.png" alt="Gm" />
        <img src="/images/chord-diagrams/Gsm.png" alt="G#m" />
      </div>
    </div>
  )
}

export default ChordSheets
