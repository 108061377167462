import { Link } from 'react-router-dom'

function BarreChords() {
  return (
    <div>
      <div>
        <h1>Barre Chords</h1>
      </div>

      <h2 className="s1">What is a barre chord?</h2>
      <p className="s2">
        A barre chord (also known as a bar chord) gets its name from the way in
        which it fretted. A guitarist plays a barre chord by using their finger
        as a 'bar' to press down all notes on a fret, while playing the other
        notes in the chord with the other fingers. One advantage of this type of
        chord is that you are no longer 'locked' into the open position, and can
        slide the same shape up and down the fretboard to play many other
        chords, in many different keys.
      </p>

      <h2 className="s1">How to play a barre chord</h2>
      <p className="s2">
        As you have gathered you play a barre chord by <i>barring</i> the fret
        (and doing other fancy stuff with those pretty little fingers of yours).
        Only two things matter when playing barre chords: the <i>shape</i> and
        the <i>position</i> you play it. The shape determines what type of barre
        chord you are playing (e.g major, minor) and the position determines the
        pitch of the chord. We will start by playing an F major barre chord on
        the first fret.
      </p>

      <h2 className="s1">Major barre chords</h2>

      <p className="s2">
        The shape below corresponds to the F major barre chord (when played on
        the low E string). When this shape is played it will be a major chord of
        the root note. The first chord in the diagram is an F Major chord as it
        played on the first fret.
      </p>

      <h2 className="s1">Minor barre chords</h2>

      <p className="s2">
        To play a minor chord is very similar, the only thing that changes is
        the note played on the G string. Simply remove your finger from the G
        string and let your barring finger do it's magic. With these two shapes,
        you can play any major or minor chord on the bottom string.
      </p>

      <img
        className="d1"
        src="/images/chord-diagrams/F-barre.png"
        alt="F Major Barre Chord"
      />
      <img
        className="d1"
        src="/images/chord-diagrams/Fm-barre.png"
        alt="F Minor Barre Chord"
      />

      <h2 className="s1">Barre chords on the A string</h2>
      <p className="s2">
        Major and minor barre chords on the 2nd string (A string) follow a
        different shape. The shapes are similar however it is important to learn
        the difference. Below is the diagram for both major and minor B chords
        on the A string.
      </p>

      <img
        className="d1"
        src="/images/chord-diagrams/B.png"
        alt="B Major Barre Chord"
      />
      <img
        className="d1"
        src="/images/chord-diagrams/Bm.png"
        alt="B Minor Barre Chord"
      />

      <h2 className="s1">Other barre chords</h2>
      <p className="s2">
        While major and minor are the most common barre chords, there exists
        many others such as 7th chords or diminished chords. These add extra
        flare to music, and can bring unique emotions and feelings to a
        composition. Below are two examples of these chords, a C major 7th and
        D# diminished.
      </p>

      <img
        className="d1"
        src="/images/chord-diagrams/C7-barre.png"
        alt="C Major 7 Barre Chord"
      />
      <img
        className="d1"
        src="/images/chord-diagrams/Dsharpdim.png"
        alt="D# Dimished Barre Chord"
      />

      <h2 className="s1">How to use barre chords</h2>
      <p className="s2">
        Barre chords are used in the exact same way any other chord. In fact a
        barre chord is completely equivalent to it's open counterpart (if it has
        one). For example, an open A Major chord is equivalent to an A major
        barre chord. The only difference between the two is how it sounds and
        where it is played.
      </p>
      <p className="s2">
        Head on over to our <Link to="/chords-in-key">Chords in Key</Link>{' '}
        section if you want to learn more about chord progressions, or to our{' '}
        <Link to="/chords/chord-sheets">Chord Sheets</Link> section to learn to
        play more chords.
      </p>

      <p className="next">
        Up next <Link to="/chords/power-chords">Power chords</Link>
      </p>
      <br />
    </div>
  )
}

export default BarreChords
