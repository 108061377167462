import { Link } from 'react-router-dom'

function OpenChords() {
  return (
    <div>
      <div>
        <h1>Open Chords</h1>
      </div>

      <h2 className="s1">What is an open chord?</h2>
      <p className="s2">
        Open Chords get their name from the fact that they are played from the
        "open position". What is the open position you ask? Great question. The
        open position is generally chords that are played with one or more open
        strings. For example the chord shown below is a open F Major, a very
        common open chord. Notice how the player has their fingers in the "open"
        position.
      </p>

      <h2 className="s1">How to play an open chord</h2>
      <p className="s2">
        Chord diagrams are a great way to learn how to play open chords (as you
        will see!). The diagram below is a common chord known as A Major. If
        you're completely new, don't worry what this means. You can just think
        of it as a name. If you would like to learn more about notes and scales
        check out our <Link to="/scales">Scales</Link> section.
      </p>

      <p className="s2">
        To play this chord, place your fingers on all the black dots, and strum
        every string that does not have an "X" on the diagram. In this case,
        that's every string besides the low E string (also known as the bottom
        string). If you can do that, congratulations! You have played an open
        chord.
      </p>

      <h2 className="s1">How to use open chords</h2>
      <p className="s2">
        Open chords can be used to play hundreds (if not thousands) of tunes.
        Everything from The Beatles to Metallica uses open chords. The trick is
        writing a smash hit is to play multiple chords that belong to a common
        key, in what is known as a <i>chord progression</i>. For example, a
        common chord progression in the key of A Major is A-D-E. Which is a
        I-IV-V chord progression.
      </p>

      <img
        src="/images/chord-diagrams/A.png"
        alt="A Major"
        style={{ maxWidth: '50%' }}
      />
      <img
        src="/images/chord-diagrams/D.png"
        alt="D Major"
        style={{ maxWidth: '50%' }}
      />
      <img
        src="/images/chord-diagrams/E.png"
        alt="E Major"
        style={{ maxWidth: '50%' }}
      />

      <p className="s2">
        Head on over to our <Link to="/chords-in-key">Chords in Key</Link>{' '}
        section if you want to learn more about chord progressions, or to our{' '}
        <Link to="/chords/chord-sheets">Chord Sheets</Link> section to learn to
        play more chords.
      </p>

      <p className="next">
        Up next <Link to="/chords/barre-chords">Barre chords</Link>
      </p>
      <br />
    </div>
  )
}

export default OpenChords
