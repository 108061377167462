export const chordLookup: Record<string, any> = {
  C: [
    ['Cmaj', 'Dmin', 'Emin', 'Fmaj', 'Gmaj', 'Amin', 'Bdim'],
    ['Cmaj7', 'Dmin7', 'Emin7', 'Fmaj7', 'G7', 'Amin7', 'Bm7b5'],
  ],
  Db: [
    ['Dbmaj', 'Ebmin', 'Fmin', 'Gbmaj', 'Abmaj', 'Bbmin', 'Cdim'],
    ['Dbmaj7', 'Ebmin7', 'Fmin7', 'Gbmaj7', 'Ab7', 'Bbmin7', 'Cm7b5'],
  ],
  D: [
    ['Dmaj', 'Emin', 'F#min', 'Gmaj', 'Amaj', 'Bmin', 'C#dim'],
    ['Dmaj7', 'Emin7', 'F#min7', 'Gmaj7', 'A7', 'Bmin7', 'C#m7b5'],
  ],
  Eb: [
    ['Ebmaj', 'Fmin', 'Gmin', 'Abmaj', 'Bbmaj', 'Cmin', 'Ddim'],
    ['Ebmaj7', 'Fmin7', 'Gmin7', 'Abmaj7', 'Bb7', 'Cmin7', 'Dm7b5'],
  ],
  E: [
    ['Emaj', 'F#min', 'G#min', 'Amaj', 'Bmaj', 'C#min', 'D#dim'],
    ['Emaj7', 'F#min7', 'G#min7', 'Amaj7', 'B7', 'C#min7', 'D#m7b5'],
  ],
  F: [
    ['Fmaj', 'Gmin', 'Amin', 'Bbmaj', 'Cmaj', 'Dmin', 'Edim'],
    ['Fmaj7', 'Gmin7', 'Amin7', 'Bbmaj7', 'C7', 'Dmin7', 'Em7b5'],
  ],
  Gb: [
    ['Gbmaj', 'Abmin', 'Bbmin', 'Bmaj', 'Dbmaj', 'Ebmin', 'Fdim'],
    ['Gbmaj7', 'Abmin7', 'Bbmin7', 'Bmaj7', 'Db7', 'Ebmin7', 'Fm7b5'],
  ],
  G: [
    ['Gmaj', 'Amin', 'Bmin', 'Cmaj', 'Dmaj', 'Emin', 'F#dim'],
    ['Gmaj7', 'Amin7', 'Bmin7', 'Cmaj7', 'D7', 'Emin7', 'F#m7b5'],
  ],
  Ab: [
    ['Abmaj', 'Bbmin', 'Cmin', 'Dbmaj', 'Ebmaj', 'Fmin', 'Gdim'],
    ['Abmaj7', 'Bbmin7', 'Cmin7', 'Dbmaj7', 'Eb7', 'Fmin7', 'Gm7b5'],
  ],
  A: [
    ['Amaj', 'Bmin', 'C#min', 'Dmaj', 'Emaj', 'F#min', 'G#dim'],
    ['Amaj7', 'Bmin7', 'C#min7', 'Dmaj7', 'E7', 'F#min7', 'G#m7b5'],
  ],
  Bb: [
    ['Bbmaj', 'Cmin', 'Dmin', 'Ebmaj', 'Fmaj', 'Gmin', 'Adim'],
    ['Bbmaj7', 'Cmin7', 'Dmin7', 'Ebmaj7', 'F7', 'Gmin7', 'Am7b5'],
  ],
  B: [
    ['Bmaj', 'C#min', 'D#min', 'Emaj', 'F#maj', 'G#min', 'A#dim'],
    ['Bmaj7', 'C#min7', 'D#min7', 'Emaj7', 'F#7', 'G#min7', 'A#m7b5'],
  ],
  Cm: [
    ['Cmin', 'Ddim', 'Ebmaj', 'Fmin', 'Gmin', 'Abmaj', 'Bbmaj'],
    ['Cmin7', 'Dm7b5', 'Ebmaj7', 'Fmin7', 'Gmin7', 'Abmaj7', 'Bb7'],
  ],
  Dbm: [
    ['C#min', 'D#dim', 'Emaj', 'F#min', 'G#min', 'Amaj', 'Bmaj'],
    ['C#min7', 'D#m7b5', 'Emaj7', 'F#min7', 'G#min7', 'Amaj7', 'B7'],
  ],
  Dm: [
    ['Dmin', 'Edim', 'Fmaj', 'Gmin', 'Amin', 'Bbmaj', 'Cmaj'],
    ['Dmin7', 'Em7b5', 'Fmaj7', 'Gmin7', 'Amin7', 'Bbmaj7', 'C7'],
  ],
  Ebm: [
    ['Ebmin', 'Fdim', 'Gbmaj', 'Abmin', 'Bbmin', 'Bmaj', 'Dbmaj'],
    ['Ebmin7', 'Fm7b5', 'Gbmaj7', 'Abmin7', 'Bbmin7', 'Bmaj7', 'Db7'],
  ],
  Em: [
    ['Emin', 'F#dim	', 'Gmaj', 'Amin', 'Bmin', 'Cmaj', 'Dmaj'],
    ['Emin7', 'F#m7b5', 'Gmaj7', 'Amin7', 'Bmin7', 'Cmaj7', 'D7'],
  ],
  Fm: [
    ['Fmin', 'Gdim', 'Abmaj', 'Bbmin', 'Cmin', 'Dbmaj', 'Ebmaj'],
    ['Fmin7', 'Gm7b5', 'Abmaj7', 'Bbmin7', 'Cmin7', 'Dbmaj7', 'Eb7'],
  ],
  Gbm: [
    ['F#min', 'G#dim', 'Amaj', 'Bmin', 'C#min', 'Dmaj', 'Emaj'],
    ['F#min7', 'G#m7b5', 'Amaj7', 'Bmin7', 'C#min7', 'Dmaj7', 'E7'],
  ],
  Gm: [
    ['Gmin', 'Adim', 'Bbmaj', 'Cmin', 'Dmin', 'Ebmaj', 'Fmaj'],
    ['Gmin7', 'Am7b5', 'Bbmaj7', 'Cmin7', 'Dmin7', 'Ebmaj7', 'F7'],
  ],
  Abm: [
    ['Abmin', 'Bbdim', 'Bmaj', 'Dbmin', 'Ebmin', 'Fbmaj', 'Gbmaj'],
    ['Abmin7', 'Bbm7b5', 'Bmaj7', 'Dbmin7', 'Ebmin7', 'Fbmaj7', 'Gb7'],
  ],
  Am: [
    ['Amin', 'Bdim', 'Cmaj', 'Dmin', 'Emin', 'Fmaj', 'Gmaj'],
    ['Amin7', 'Bm7b5', 'Cmaj7', 'Dmin7', 'Emin7', 'Fmaj7', 'G7'],
  ],
  Bbm: [
    ['Bbmin', 'Cdim', 'Dbmaj', 'Ebmin', 'Fmin', 'Gbmaj', 'Abmaj'],
    ['Bbmin7', 'Cm7b5', 'Dbmaj7', 'Ebmin7', 'Fmin7', 'Gbmaj7', 'Ab7'],
  ],
  Bm: [
    ['Bmin', 'C#dim', 'Dmaj', 'Emin', 'F#min', 'Gmaj', 'Amaj'],
    ['Bmin7', 'C#m7b5', 'Dmaj7', 'Emin7', 'F#min7', 'Gmaj7', 'A7'],
  ],
}
