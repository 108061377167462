export const chordLookup: Record<string, any> = {
  C: {
    maj: [0, 1, 0, 2, 3, -1],
    min: [3, 4, 5, 5, 3, -1],
    dim: [2, 1, -1, 1, 3, -1],
  },
  'C#': {
    maj: [4, 6, 6, 6, 4, -1],
    min: [4, 5, 6, 6, 4, -1],
    dim: [0, 5, 6, 5, 4, -1],
  },
  Db: {
    maj: [4, 6, 6, 6, 4, -1],
    min: [4, 5, 6, 6, 4, -1],
    dim: [0, 5, 6, 5, 4, -1],
  },
  D: {
    maj: [2, 3, 2, 0, -1, -1],
    min: [1, 3, 2, 0, -1, -1],
    dim: [1, 3, 1, 0, -1, -1],
  },
  'D#': {
    maj: [6, 8, 8, 8, 6, -1],
    min: [6, 7, 8, 8, 6, -1],
    dim: [2, -1, 2, 1, -1, -1],
  },
  Eb: {
    maj: [6, 8, 8, 8, 6, -1],
    min: [6, 7, 8, 8, 6, -1],
    dim: [2, -1, 2, 1, -1, -1],
  },
  E: {
    root: 'E',
    maj: [0, 0, 1, 2, 2, 0],
    min: [0, 0, 0, 2, 2, 0],
    dim: [6, 5, 0, 5, 7, -1],
  },
  F: {
    root: 'F',
    maj: [1, 1, 2, 3, 3, 1],
    min: [1, 1, 1, 3, 3, 1],
    dim: [1, 0, 1, 3, -1, -1],
  },
  'F#': {
    root: 'F#',
    maj: [2, 2, 3, 4, 4, 2],
    min: [2, 2, 2, 4, 4, 2],
    dim: [2, 1, 2, -1, 0, 2],
  },
  Gb: {
    root: 'Gb',
    maj: [2, 2, 3, 4, 4, 2],
    min: [2, 2, 2, 4, 4, 2],
    dim: [2, 1, 2, -1, 0, 2],
  },
  G: {
    maj: [3, 0, 0, 0, 2, 3],
    min: [3, 3, 3, 5, 5, 3],
    dim: [9, 8, 0, 8, 10, -1],
  },
  'G#': {
    maj: [4, 4, 5, 6, 6, 4],
    min: [4, 4, 4, 6, 6, 4],
    dim: [4, 0, 4, 0, 2, 4],
  },
  Ab: {
    maj: [4, 4, 5, 6, 6, 4],
    min: [4, 4, 4, 6, 6, 4],
    dim: [4, 0, 4, 0, 2, 4],
  },
  A: {
    maj: [0, 2, 2, 2, 0, -1],
    min: [0, 1, 2, 2, 0, -1],
    dim: [-1, 1, 2, 1, 0, -1],
  },
  'A#': {
    maj: [1, 3, 3, 3, 1, -1],
    min: [1, 2, 3, 3, 1, -1],
    dim: [0, 2, 3, 2, 1, -1],
  },
  Bb: {
    maj: [1, 3, 3, 3, 1, -1],
    min: [1, 2, 3, 3, 1, -1],
    dim: [0, 2, 3, 2, 1, -1],
  },
  B: {
    maj: [2, 4, 4, 4, 2, -1],
    min: [2, 3, 4, 4, 2, -1],
    dim: [-1, -1, 7, 9, 8, 7],
  },
}
