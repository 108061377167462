import { Link } from 'react-router-dom'

function ExerciseOne() {
  return (
    <div>
      <h1>1234 Warmup Exercise</h1>

      <h2 className="s1">How to play</h2>
      <p className="s2">
        This warmup is great to do before you start your guitar practice. It
        helps with loosening your fingers and becoming familiar with the fret
        board. Over time practicing this exercise, you will build{' '}
        <em>muscle memory</em>, and play it faster with greater ease.
      </p>

      <p className="s2">
        To play it, start on the first fret of the low E string with your index
        finger, then play the second fret with your middle finger, until you
        reach the fourth fret with your pinky. Then continue on the next string
        and so forth.
      </p>

      <p className="s2">
        Once you reach the high E string, play it backwards until you reach the
        low E string. After this slide your index finger to the second fret and
        repeat the exact same motion. Play it on many different starting points,
        backwards, forwards, up the neck, don't be afraid to get creative with
        it! As long as you're using <strong>each of your fingers</strong>, it is
        great practice.
      </p>

      <p className="next1">
        Up next <Link to="/tips/guitar-maintenance">Guitar Maintenance</Link>
      </p>
    </div>
  )
}

export default ExerciseOne
