function CMajorTable() {
  return (
    <div className="chord-table-wrapper">
      <div className="chord-table">
        <table id="major-table">
          <tr>
            <th>I</th>
            <th>II</th>
            <th>III</th>
            <th>IV</th>
            <th>V</th>
            <th>VI</th>
            <th>VII</th>
          </tr>
          <tr>
            <td>Cmaj</td>
            <td>Dmin</td>
            <td>Emin</td>
            <td>Fmaj</td>
            <td>Gmaj</td>
            <td>Amin</td>
            <td>Bdim</td>
          </tr>
        </table>
      </div>
    </div>
  )
}

export default CMajorTable
